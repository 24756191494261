import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { SafeAreaView, View, Pressable, TextInput } from 'react-native'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
const queryClient = new QueryClient()
// Configuración twrnc
import tw from "../../styles/tailwind";
import configureStore from "../../store/configureStore";
const { store, persistor } = configureStore();

import { MaterialIcons } from '@expo/vector-icons';
import { useRoute } from '@react-navigation/native';
import Text from '../../components/Text';

import ValidadoIcon from '../../assets/icons/validado';
import { setPagoTarjetaReportado } from '../../store/slices/pagosTarjeta';

export default function PagoExitoso({navigation}) {
    const route = useRoute(); 
    const dispatch = useDispatch();
    const Contrato = useSelector(state => state.contratos);
    const { checkout_id, order_id, payment_status } = route.params;
    const noContrato = Contrato.contratos.numeroContrato;
    const idEmpresa = Contrato.contratos.idEmpresa;
    const idActivacion = Contrato.contratos.codigoActivacion;
    const idAdeudo = Contrato.contratos.idAdeudo;
    useEffect(() => {
        dispatch(setPagoTarjetaReportado(idEmpresa, idActivacion, idAdeudo, order_id));
    }, [dispatch, idEmpresa, idActivacion, idAdeudo, order_id]);
    return(
        <SafeAreaView style={tw`flex-1  items-center`}>
            <View style={[tw`w-full bg-encabezado border-b border-primary shadow-lg justify-center items-center`, { height: 350 }]}>
                <View style={[tw`items-center`]} >
                    <ValidadoIcon  width={100} height={100}/>
                    <Text style={tw`text-primary text-5xl mt-4 font-500`}>¡Pago Exitoso!</Text>
                </View>
            </View>
            
            <View style={tw`w-full flex-1  items-center`}>
                <View style={tw`w-full p-6`}>
                    <View style={tw`p-6 `}>
                    <View>
                            <Text style={tw`text-primaryText text-xl text-left mt-2`}>Se ha enviado comprobante de pago y el contrato a su correo electrónico</Text>
                            <Text style={tw`text-primaryText text-xl font-500 text-center mb-2 mt-16`}>Número de contrato</Text>
                            <Text style={tw`bg-tabs text-datosText text-2xl text-center py-2 rounded-lg tracking-widest`}>{noContrato}</Text>
                        </View>
                    </View>
                </View>
                <View style={tw`w-full flex-1 justify-center mt-16 bg-white p-6 shadow-lg`}>
                        <Pressable style={tw`bg-primary py-4 rounded-full`} onPress={() => goToInicio()}>
                            <Text style={tw`text-center text-xl  text-white text-xl`}>Terminar</Text>
                        </Pressable>
                </View>
            </View>
        </SafeAreaView>
    );

    function goToInicio(){
        navigation.navigate('Inicio.Index');
    }
    function goToMetodoPago(){
        navigation.navigate('MetodoPagoScreen.Index');
    }
};