import { useEffect } from 'react';
import { BackHandler, Platform, Alert } from 'react-native';
import { StatusBar } from 'expo-status-bar';
import { SafeAreaProvider } from 'react-native-safe-area-context';

import useCachedResources from './hooks/useCachedResources';
import useColorScheme from './hooks/useColorScheme';
import Navigation from './navigation';

import Toast, { ToastProvider } from 'react-native-toast-notifications'


//  react-query
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
const queryClient = new QueryClient()


// Configuración twrnc
import tw from "./styles/tailwind";
import { useDeviceContext } from 'twrnc'


//-------------------------Configuración redux-------------------------------------------//
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import configureStore from "./store/configureStore";

const { store, persistor } = configureStore();
//--------------------------------------------------------------------------------------//

//-------------------------Configuración de API-------------------------------------------//
/*import api from "./utils/api";
import Constants from 'expo-constants'

// api.defaults.baseURL = process.env.API_URL // Para usar con dot-env (no instalado por default)
api.defaults.baseURL = Constants.expoConfig?.extra?.API_URL;

// Para inyectar el token en las peticiones
api.interceptors.request.use(config => {
  const { auth } = store.getState()

  config.headers.Accept =  'application/json';

  if (auth.isLogged) {
    config.headers.Authorization = `Bearer ${auth.token}`
  }

  return config;
}, error => {
  return Promise.reject(error);
});


// Response interceptor
api.interceptors.response.use(response => {
  // Cualquier http 2xx
  return response;
}, function (error) {
  // TODO
  // error.response.status === 401
  return Promise.reject(error);
})*/
//--------------------------------------------------------------------------------------//



export default function App() {
  const isLoadingComplete = useCachedResources();
  const colorScheme = useColorScheme();

  useDeviceContext(tw);

  useEffect(() => {
    if (Platform.OS !== 'web') {
      const backAction = () => {
        // Tu lógica para manejar el botón de retroceso
        Alert.alert("Espera!", "Estas seguro que quieres ir atras?", [
          {
            text: "Cancel",
            onPress: () => null,
            style: "cancel"
          },
          { text: "Si", onPress: () => BackHandler.exitApp() }
        ]);
        return true;
      };

      const backHandler = BackHandler.addEventListener(
        "hardwareBackPress",
        backAction
      );

      // Cleanup
      return () => backHandler.remove();
    }
  }, []);


  if (!isLoadingComplete) {
    return null;
  } else {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <QueryClientProvider client={queryClient}>
            <ToastProvider offsetTop={80}>

              <SafeAreaProvider>
                <Navigation colorScheme={colorScheme} />
                <StatusBar />
              </SafeAreaProvider>

            </ToastProvider>
          </QueryClientProvider>
        </PersistGate>
      </Provider>
    );
  }
}
