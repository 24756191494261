import React from 'react';
import { View,  StyleSheet, Pressable } from 'react-native';
import tw from '../../styles/twrnc-config';
import Text from '../../components/Text';
import { useRoute } from '@react-navigation/native';
import { useSelector, useDispatch } from "react-redux";
import { setPagoDomicilio } from '../../store/slices/pagosDomicilio';

const FooterMetodoPagoEfectivo = ({ navigation, valueDia }) => {
  const dispatch = useDispatch();
  const Contrato = useSelector(state => state.contratos);
  const codigoActivacion = Contrato.contratos.codigoActivacion;

  const id_empresa = Contrato.contratos.idEmpresa;

  const goToMetodoPagoCompletado = () => {
    dispatch(setPagoDomicilio(valueDia, codigoActivacion, id_empresa)).then((result) => {
      //console.log("RESULT METODO PAGO COMPLETADO : ",result);
      if (result.Success === true) {
        const article_text = valueDia === "Hoy" ? "" : "el "
        const end_text = valueDia === "Hoy" ? "" : ". El horario de visita es de 09:00 a 18:00 y depende de la disponibilidad"
        const mensaje = article_text + result.messagePago + end_text;
        navigation.navigate('CompletaPagoEfectivoScreen.Index',{ mensaje});
      }
    }).catch((error: any) => {
      console.log("Error: ", error);
      alert("No se pudo registrar exitosamente el dia del pago en domicilio.");
    });
  }

  return (
    <View style={[tw`flex-row w-full pr-5 pl-5 pb-6 pt-6 justify-center items-center shadow-lg bg-white`]}>
      {valueDia !== ""  ? (
        <Pressable style={[tw`flex-1 py-3 mx-2 bg-primary rounded-full`, styles.buttonFooter]} onPress={goToMetodoPagoCompletado}>
          <Text style={[tw`text-center text-white text-xl`]}>Continuar</Text>
        </Pressable>
      ) : (
        <Pressable style={[tw`flex-1 py-3 mx-2 bg-buttonInac rounded-full`, styles.buttonFooter]} >
          <Text style={[tw`text-center text-textButton text-xl`]}>Continuar</Text>
        </Pressable>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  buttonFooter: {
    borderColor: "#000",
    width: 150,
    height: 55,
},
});

export default FooterMetodoPagoEfectivo;
  
  