import React, { useState, useEffect } from 'react';
import { View, Pressable, Image } from 'react-native';
import { ModalFirma } from './ModalFirma';
import tw from '../../styles/twrnc-config';
import { MaterialIcons } from '@expo/vector-icons';
import { useRoute } from '@react-navigation/native';
import DocumentoPDF from './DocumentoPDF';

import CheckFirmas from '../../assets/icons/check';
import FrimaIcon from '../../assets/icons/firma';

import FooterFirmarDocs from './FooterFirmarDoc';
import Text from '../../components/Text';
import { uploadFileSign } from '../../services/General';
import { setFirmaURL } from '../../store/slices/firmas'
import { subeImagenFirma, subeImagenFirma2, subeImagenFirma3, subeImagenFirma4, subeImagenFirma5, subeImagenFirma6, subeImagenFirma7, subeImagenFirma8, subeImagenFirma9, subeImagenFirma10 } from '../../store/slices/imagenes';
import { useSelector, useDispatch } from "react-redux";

export default function FirmarDocumentosScreen({ navigation, docFirmar }) {
  const [visibleFirma, setModalVisible] = useState(false);
  const [accepted, setAccepted] = useState(false);
  const [firmas, setFirmas] = useState({});
  const dispatch = useDispatch();
  // const route = useRoute();
  // const { documento, num, id } = route.params;
  const documento = useSelector(state => state.selectedDoc.documento);
  const num = useSelector(state => state.selectedDoc.num);
  const id = useSelector(state => state.selectedDoc.id);
  const Contrato = useSelector(state => state.contratos);
  const ImagenesData = useSelector(state => state.imagenes);
  const firmasData = useSelector(state => state.firmas);
  const id_activacion = Contrato.contratos.codigoActivacion;
  const id_empresa = Contrato.contratos.idEmpresa;
  const [isLoading, setIsLoading] = useState(false);
  const [isSigning, setIsSigning] = useState(false);
  const firmasUrls = firmasData.firmasUrls || []; 
  useEffect(() => {
    if ((Contrato?.contratos?.documentos?.[num]?.fechaFirma || firmasUrls[num])) {
      setIsSigning(true);
    } else {
      setIsSigning(false);
    }
  }, [Contrato, num]);
  
  const handleFirma = (documento, nuevaFirma) => {
    setFirmas((prevFirmas) => ({
      ...prevFirmas,
      [documento]: {
        firma: nuevaFirma,
        isFirmaUploaded: true,
      },
    }));
  };

  const cambiarAcepted = () => {
    setAccepted(prevAccepted => !prevAccepted);
  }

  const closeModalFirma = () => {
    setModalVisible(false);
  };

  const dataURLtoBlob = (dataURL) => {
    const binary = atob(dataURL.split(',')[1]);
    const array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: 'image/jpeg' });
  };


  const subirFirma = async () => {
    const currentFirma = firmas[documento]?.firma;
    if (currentFirma) {
      const firmaBlob = dataURLtoBlob(currentFirma);
      const documentoNombre = documento.replace(/ /g, "_");
      const respuestaUploadFirma = await uploadFileSign(firmaBlob, id_empresa, id_activacion, `id_${id}_firma_${documentoNombre}`);

      if (respuestaUploadFirma.Success) {
        /*const dispatchActions = [subeImagenFirma, subeImagenFirma2, subeImagenFirma3, subeImagenFirma4, subeImagenFirma5, subeImagenFirma6, subeImagenFirma7, subeImagenFirma8,  subeImagenFirma9,  subeImagenFirma10];
        if (dispatchActions[num]) {
          dispatch(dispatchActions[num](respuestaUploadFirma.Success, respuestaUploadFirma.data.Location, id));
        }*/
        //console.log("Firmas 0: ", firmasData);
        let firmaURL = firmasData.firmasUrls;

        // console.log("NUEVO ARREGLO : newfirma ", respuestaUploadFirma.data.Location);
        dispatch(setFirmaURL({"newFirma":respuestaUploadFirma.data.Location}));
        // console.log("FIRMAS DATA : ******* ", firmasData.firmasUrls);
        return true;
      } else {
        alert(`Error al subir el archivo: ${respuestaUploadFirma.Error}`);
        return false;
      }
    }
  };

  const terminarProceso = async () => {
    setIsLoading(true);
    const firmaSubida = await subirFirma();
    setIsLoading(false);

    if (firmaSubida) {
      navigation.navigate('ListFirmasScreen.Index');
    }
  };

  const renderFirmarDocumento = () => (
    <>
      <View style={tw`mt-6`}>
        <Text style={tw`text-datosText text-lg`}>
          Abre el documento y cuando lo hayas leído firmalo en la opción siguiente.
        </Text>
      </View>
      <Pressable
        style={[tw`border-2 bg-white border-gray-300 rounded-lg pt-8 pb-8 mt-5 justify-center items-center`]}
        onPress={() => setModalVisible(true)}
      >
        {firmas[documento]?.firma ? (
          <Image
            source={{ uri: firmas[documento].firma }}
            style={{ width: 190, height: 90, resizeMode: 'contain' }}
          />
        ) : (
          <>
            <FrimaIcon width={48} height={48} style={[tw`mr-3`]} />
            <Text style={[tw`text-primaryText text-xl`]}>Toca para firmar</Text>
          </>
        )}
      </Pressable>
    </>
  );

  const renderFooterFirmarDocs = () => (
    <FooterFirmarDocs
      navigation={navigation}
      firmas={firmas}
      accepted={accepted}
      documento={documento}
      terminarProceso={terminarProceso}
      isLoading={isLoading}
    />
  );

  const renderAceptarTerminos = () => (
    <View style={tw`border bg-white border-primary rounded-lg shadow-lg p-6 mt-10 justify-center items-center `}>
      <Text style={tw`text-lg text-primaryText text-center`}>
        DE CONFORMIDAD CON EL ARTÍCULO 89 DEL CÓDIGO DE COMERCIO, ACEPTO QUE LA FIRMA QUE SE PONE EN ESTE MEDIO ES LA QUE UTILIZO EN TODOS LOS ACTOS QUE CELEBRÓ Y QUE LA MISMA TIENE VALIDEZ COMO SI SE ESTAMPARE PERSONALMENTE
      </Text>
      <Pressable
        style={tw`p-5 rounded-xl bg-tabs mt-5`}
        onPress={cambiarAcepted}
      >
        <View style={[tw`flex-row justify-between`]}>
          {accepted ? 
            (<CheckFirmas fill={'#45ba47'} width={28} height={28} style={[tw` mr-3`]} />) : 
            (<MaterialIcons name="circle" color="white" size={28} style={[tw`mr-3`]} />)
          }
          <Text style={tw`text-center text-xl font-500`}>
            Estoy de acuerdo
          </Text>
        </View>
      </Pressable>
    </View>
  );

  return (
    <>
      <View style={tw`bg-bgGrey w-full flex-grow bg-bgGrey justify-between mt-10 mb-5 pl-6 pr-6`}>
        <View>
          <DocumentoPDF documento={documento} id={id} docFirmar={docFirmar} />
          
          {(!isSigning  && num >= 0 && num <= 10) && renderFirmarDocumento()}
          {(!isSigning && num >= 0 && num <= 10) && renderAceptarTerminos()}
        </View>
      </View>

      <View style={tw`bg-bgGrey`}>
        <ModalFirma 
          visibleFirma={visibleFirma}
          closeModalFirma={closeModalFirma}
          documento={documento}
          setFirma={(nuevaFirma) => handleFirma(documento, nuevaFirma)}
          numeroDoc={num}
        />
        {(!isSigning && num >= 0 && num <= 10) && renderFooterFirmarDocs()}
      </View>
    </>
  );
}
