import React, { useEffect, useState } from 'react';
import { SafeAreaView, ActivityIndicator, StyleSheet, View } from 'react-native';
import { useRoute } from '@react-navigation/native';
import { useDispatch } from 'react-redux';
import tw from '../../styles/twrnc-config';
import HeaderInicio from './HeaderInicio';
import ContentInicio from './ContentInicio';
import FooterInicio from './FooterInicio';
import Invalid from './Invalid';
import { getContratoData } from '../../store/slices/contratos';
import { setEmptyUploadImagen } from '../../store/slices/imagenes';
import { setEmptyFirmaDocumento } from '../../store/slices/firmas';
import { setToken } from '../../store/slices/token/tokenSlice';
import { setNumeroContratoIdEmpresa } from '../../store/slices/selectedDoc/selectedDocSlice';
import { setCleanDocumentoUrls } from '../../store/slices/documentos';

export default function InicioScreen({ navigation }) {
    const dispatch = useDispatch();
    const [contrato, setContrato] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const route = useRoute(); 

    useEffect(() => {
        const { t } = route.params;
        dispatch(setToken(t));

        if (t === "undefined" || t === undefined) {
            alert("Solicita un token.");
            setIsLoading(false); // Stop loading if no token is provided
            return; 
        }

        dispatch(getContratoData(t)).then((result) => {
            if (result.Success === true) {
                setContrato(result.dataContrato);
                if (Object.keys(result.dataContrato).length === 0) {
                    setMensajeMostrar("No existe información para ese token, Favor de solicitar un token válido.");
                }
            } else {
                alert("No existe información para ese token, Favor de solicitar un token válido.");
            }
            setIsLoading(false); // Stop loading after fetching data
            dispatch(setEmptyUploadImagen());
            dispatch(setEmptyFirmaDocumento());
            dispatch(setCleanDocumentoUrls());
            dispatch(setNumeroContratoIdEmpresa({"numeroContrato":result.dataContrato.numeroContrato, "idEmpresa":result.dataContrato.idEmpresa, "idActivacion":result.dataContrato.codigoActivacion,}));
        }).catch((error) => {
            console.log("Error: ", error);
            setIsLoading(false); // Stop loading if there's an error
        });
    }, [route.params, dispatch]);

    const { codigoActivacion, idEmpresa, logo_empresa } = contrato;
    console.log("Contrato: ",contrato);
    return (
        <SafeAreaView style={tw`flex-1 bg-white`}>
            {isLoading ? (
                <View style={styles.loadingOverlay}>
                    <ActivityIndicator size="large" color="#000000" />
                </View>
            ) : (
                Object.keys(contrato).length !== 0 || contrato == 'undefined' ? (
                    <>
                        <HeaderInicio navigation={navigation} logoEmpresa={logo_empresa} />
                        <ContentInicio navigation={navigation} contrato={contrato} />
                        <FooterInicio navigation={navigation} codigoActivacion={codigoActivacion} idEmpresa={idEmpresa} />
                    </>
                ) : (
                    <Invalid navigation={navigation} />
                )
            )}
        </SafeAreaView>
    );
}

const styles = StyleSheet.create({
    loadingOverlay: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(255, 255, 255, 1)', // White background
    },
});
