
import Constants from 'expo-constants';
import AWS from 'aws-sdk';

AWS.config.update({
    region: Constants.expoConfig?.extra?.AWS_REGION,
    accessKeyId: Constants.expoConfig?.extra?.AWS_APIKEY,
    secretAccessKey: Constants.expoConfig?.extra?.AWS_SEC_APIKEY,
});

const s3 = new AWS.S3();

export const uploadFile = async (uriFrenteINE, id_empresa, id_activacion, tipo_imagen) => {
    let key = "";
    let contentTypeData = "";
    //console.log(tipo_imagen);
    if (tipo_imagen === 'comprobante_domicilio' || tipo_imagen === 'identificacion_frente' || tipo_imagen === 'identificacion_reverso') {
      
      key = `${id_activacion}/${id_empresa}_${id_activacion}_${tipo_imagen}.jpg`;
      contentTypeData = 'image/jpeg'
    } else {
      key = `${id_activacion}/firma_${id_empresa}_${id_activacion}_${tipo_imagen}.jpg`;
      contentTypeData = 'image/jpeg'
    }

    return new Promise((resolve, reject) => {
        fetch(uriFrenteINE)
          .then(response => response.blob())
          .then(blob => {
            const params = {
              Bucket: Constants.expoConfig?.extra?.AWS_BUCKET_IMAGES,
              Key: key,
              Body: blob,
              ContentType: contentTypeData,
            };
    
            s3.upload(params, (err, data) => {
              if (err) {
                //console.log('Error uploading image:', err);
                reject({"Success": false, "Error": err});
              } else {
                //console.log('Successfully uploaded image:', data);
                resolve({"Success": true, "Message": "Successfully uploaded image", "data": data});
              }
            });
          })
          .catch(error => {
            console.error('Error:', error);
            reject({"Success": false, "Error": err});
          });
      });

}

export const uploadFileSign = async (blob, id_empresa, id_activacion, tipo_imagen) => {
  let key = "";
  let contentTypeData = "";
  key = `${id_activacion}/firma_${id_empresa}_${id_activacion}_${tipo_imagen}.jpg`;
  contentTypeData = 'image/jpeg';

  return new Promise((resolve, reject) => {
      const params = {
        Bucket: Constants.expoConfig?.extra?.AWS_BUCKET_IMAGES,
        Key: key,
        Body: blob,
        ContentType: contentTypeData,
      };
  
      s3.upload(params, (err, data) => {
        if (err) {
          //console.log('Error uploading image:', err);
          reject({"Success": false, "Error": err});
        } else {
          //console.log('Successfully uploaded image:', data);
          resolve({"Success": true, "Message": "Successfully uploaded image", "data": data});
        }
      });
    });

}
