import React, {useState, useEffect} from 'react';
import { Button, StyleSheet, TextInput, View, Pressable, Image } from 'react-native';
import { useRoute } from '@react-navigation/native';
import DropDownPicker from 'react-native-dropdown-picker';
import api from "../../utils/api"
import tw from '../../styles/tailwind';
import Text from '../../components/Text';
import { Montserrat_400Regular } from '@expo-google-fonts/montserrat';

/* Iconos */
import { FontAwesome } from '@expo/vector-icons';
import { useSelector, useDispatch } from "react-redux";


export default function FormaCompletaPagoEfectivo({navigation}) {
    const [valueDia, setValueDia] = useState('');
    const [valueHorario, setValueHorario] = useState('');
    const route = useRoute();
    const { mensaje } = route.params;
    const Contrato = useSelector(state => state.contratos);
    const id_activacion = Contrato.contratos.codigoActivacion;
    const id_empresa = Contrato.contratos.idEmpresa;
    

    const onChangeSelect = () => {
        console.log("ONCHANGE");
    }
    
    return (
        <View style={tw`w-full flex-grow mt-15 mb-10 pl-7 pr-7`}>
            <Text style={tw`text-textPrimary text-xl mb-2 text-center`}>Ha finalizado exitosamente su trámite</Text>
            <View style={[tw`mt-4`]}>
                <Text style={tw`text-primaryText font-500 text-xl text-center mb-4 `}>Número de contrato</Text>
                <Text style={tw`bg-tabs text-datosText text-2xl text-center py-2 rounded-lg tracking-widest`}>{id_activacion}</Text>
            </View>
            <View style={[tw`mt-4`]}>
                <Text style={tw`text-primaryText font-500 text-xl text-center mb-4 `}>Datos de Cobro</Text>
                <Text style={tw`bg-tabs text-datosText text-xl text-center py-2 rounded-lg pr-15 pl-15 text-center`}
                    multiline={true}
                    numberOfLines={7}
                >Visita programada para {mensaje}</Text>
            </View>
        </View>
    );
  };

  
  