import { contratosAPI } from '../../api/contratosApi';
import { startPagoDomicilio, setPagoDomicilioData, errorPagoDomicilio, endPagoDomicilio } from './pagosDomicilioSlice';
import Constants from 'expo-constants'

export const setPagoDomicilio = (tipoDiaPago:string,  numeroContrato:string, idEmpresa:string) => {
    return async( dispatch:any ) => {
        dispatch( startPagoDomicilio() );
        let mensajeSave="";
        if(tipoDiaPago === 'Hoy'){
            mensajeSave="Hoy mismo : El horario de visita es de 09:00 a 18:00 y depende de la disponibilidad";
        }else{
            mensajeSave="Día siguiente : Los días son de lunes a sábado";
        }

        const responseData = await contratosAPI.post(`/contratos/pago-domicilio`, 
            {
                numeroContrato: numeroContrato,
                idEmpresa: idEmpresa,
                mensaje: mensajeSave,
                tipoDiaPago: tipoDiaPago
            }
        )
        .then(data => {
            if(data.data.register==="OK"){
                dispatch( setPagoDomicilioData({
                    statusPagoDomicilio: "registrado",
                    isLoading: false,
                    messagePagoDomicilio: mensajeSave,
                    errorMessage: ""
                }));
                dispatch( endPagoDomicilio() );
                return {"Success": true, "messagePago": data.data.Message};
            }else{
                dispatch( errorPagoDomicilio({
                    statusPagoDomicilio: "no_registrado",
                    isLoading: false,
                    messagePagoDomicilio: "",
                    errorMessage: data.data.errorMessage
                }));
                dispatch( endPagoDomicilio() );
                return {"Success": true, "messagePago": data.data.Message};
            }
        })
        .catch(error => {
            dispatch( errorPagoDomicilio({
                statusPagoDomicilio: "no_registrado",
                isLoading: false,
                messagePagoDomicilio: "",
                errorMessage: error
            }));
            dispatch( endPagoDomicilio() );
            return {"Success": false, "messagePago": error};
        });

        return responseData;
    }
}