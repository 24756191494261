import * as React from 'react';
import { Button, StyleSheet, TextInput, View, Pressable, Image } from 'react-native'

import api from "../../utils/api"
import tw from '../../styles/twrnc-config';
import Text from '../../components/Text';
import Icon from '../../components/Icon';

import HeaderMetodoPago from './HeaderMetodoPago';
import FormaMetodoPago from './FormaMetodoPago';
import FooterMetodoPago from './FooterMetodoPago';

export default function MetodoPagoScreen({navigation}) {
  
  return (
    <View style={tw`flex-1 bg-bgGrey`}>
      <HeaderMetodoPago navigation={navigation} />
      <FormaMetodoPago navigation={navigation} />
      
    </View>
  );
}