import React, {useState, useEffect} from 'react';
import { Button, StyleSheet, TextInput, View, Pressable, Image } from 'react-native';

import api from "../../utils/api"
import tw from '../../styles/twrnc-config';
import Text from '../../components/Text';
import { useRoute } from '@react-navigation/native';
/* Iconos */
import { MaterialIcons } from '@expo/vector-icons';

const styles = StyleSheet.create({
    textInforme: {
        top: 15,
        left: 15,
        width: "100%",
        fontSize: 16,
    },
    textInforme_next: {
        top: 35,
        left: 15,
        width: "100%",
        fontSize: 16,
    }
});

export default function HeaderMetodoPagoEfectivo({navigation}) {
   
    const goToMetodoPago= () => {
        navigation.navigate('MetodoPagoScreen.Index');
    }

    return (
        <View style={[tw`bg-encabezado w-full p-5 border-b border-primary shadow-lg`]}>
            <View style={tw`flex-row items-center`}>
                <Pressable style={tw`mr-2 pt-8`} onPress={goToMetodoPago}>
                    <MaterialIcons name="arrow-back" size={28} color={tw.color("primary")} />
                </Pressable>
                <Text style={tw`text-primary text-3xl mt-8 font-500`}>Pago en Domicilio</Text>
            </View>
            <Text style={[tw`mt-3 text-xl pl-10 text-primaryText`]}>Un representante lo visitará en su domicilio para realizar el cobro.</Text>
            
        </View>
    );
  };

  
  