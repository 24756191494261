import { combineReducers, applyMiddleware } from 'redux'
import { configureStore } from '@reduxjs/toolkit';

// Reductores...
import { reducer as UserRedux } from './Reducers/auth';
import { reducer as StatusRedux } from './Reducers/status';
import ContratosRedux from './slices/contratos/contratosSlice';
import ImagenesRedux from './slices/imagenes/imagenesSlice';
import FirmasRedux from './slices/firmas/firmasSlice';
import tokenRedux from './slices/token/tokenSlice';
import selectedDocRedux from './slices/selectedDoc/selectedDocSlice';
import documentosRedux from './slices/documentos/documentosSlice';

const todoApp = combineReducers({
  auth: UserRedux,
  status: StatusRedux,
  contratos: ContratosRedux,
  imagenes: ImagenesRedux,
  firmas: FirmasRedux,
  token: tokenRedux,
  selectedDoc: selectedDocRedux,
  documentos: documentosRedux,
})


// Persist config
// Nueva implementación tomada de:
// https://redux.js.org/usage/migrating-to-modern-redux#store-setup-with-configurestore
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist'
import AsyncStorage from '@react-native-async-storage/async-storage';


const persistConfig = {
  key: 'root',
  storage: AsyncStorage,
  blacklist: [],
}


const persistedReducer = persistReducer(persistConfig, todoApp)



export default () => {
  const store = configureStore({
    // Pass previously created persisted reducer
    reducer: persistedReducer,
    middleware: getDefaultMiddleware => {
      const middleware = getDefaultMiddleware({
        // Customize the built-in serializability dev check
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
        }
      }).concat()

      // Conditionally add another middleware in dev
      if (process.env.NODE_ENV !== 'production') {
        // middleware.push(logger)
      }

      return middleware
    },
  });

  const persistor = persistStore(store)

  return { store, persistor }
}